@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,500;1,400&display=swap");
.App {
    display: flex;
    flex-direction: column;
    font-family: "Open Sans", sans-serif;
    background-color: #f5f5f5;
    font-size: 3vh;
}

a,
a:hover,
a:visited,
a:link,
a:active {
    color: inherit;
    text-decoration: none;
}

.contact-links:hover {
    text-decoration: underline;
}


/*..............................TOP CONTACTS BAR................................*/

.header-contact {
    background-color: #333;
    color: white;
    display: flex;
    justify-content: center;
    padding-top: 0.5%;
}


/*contact bar top icons*/

.header-contact img {
    width: auto;
    height: 4vh;
}


/*also used for footer*/

.media-logo {
    padding-left: 1%;
    padding-right: 1%;
}


/*..............................NAV BAR................................*/

#nav-bar {
    background-color: white;
    color: black;
    display: flex;
    position: sticky;
    position: -webkit-sticky;
    top: 0px;
    box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
    z-index: 10000;
    padding: 1rem;
    font-size: 0.75em;
}


/*logo*/

#nav-bar img {
    width: auto;
    height: 8vh;
    padding-right: 1rem;
}

.topnav a {
    float: left;
    display: flex;
    color: black;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
}


/*dropdown code*/

.dropdown {
    float: left;
    overflow: hidden;
}

.dropdown .dropbtn {
    border: none;
    outline: none;
    color: black;
    background-color: inherit;
    font-family: inherit;
    font-size: inherit;
    padding-top: 1rem;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    float: none;
    color: black;
    display: block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.topnav a:hover,
.dropdown:hover .dropbtn {
    background-color: white;
    color: black;
}

.dropdown-content a:hover {
    background-color: #ddd;
    color: black;
}

.dropdown:hover .dropdown-content {
    display: block;
}


/*..............................HOME IMAGE SECTION................................*/

.home-picture-content {
    position: absolute;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.6);
    background-size: cover;
    color: white;
    min-height: 78vh;
    width: 100%;
    text-align: center;
}

.home-picture-content h1 {
    padding-top: 6rem;
    font-size: 3.5em;
    font-family: "Open Sans", sans-serif;
}

.home-picture-content p {
    padding-left: 2rem;
    padding-right: 2rem;
}


/*..............................ABOUT SECTION................................*/

#about {
    min-height: 78vh;
    text-align: center;
}

#about-container {
    padding-top: 6rem;
    padding-left: 15%;
    padding-right: 15%;
}


/*..............................SECOND PICTURE SECTION................................*/

.secondary-picture-content {
    position: absolute;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.6);
    background-size: cover;
    color: #f1f1f1;
    min-height: 78vh;
    width: 100%;
}

.secondary-picture-content p {
    color: white;
    padding-top: 10rem;
    padding-left: 15%;
    padding-right: 15%;
}


/*..............................SERVICES PAGES/COMPONENTS................................*/


/*services pages*/

.services-sections {
    min-height: 78vh;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.services-sections p {
    padding-left: 15%;
    padding-right: 15%;
    font-size: 2.5vh;
}


/*contact us page */

#contact-us-page {
    min-height: 78vh;
    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    text-align: center;
}

#contact-us-page p {
    padding-left: 15%;
    padding-right: 15%;
    font-size: 2.5vh;
}

#contact-us-page label {
    padding-left: 15%;
    padding-right: 15%;
    font-size: 2.5vh;
}

#contact-us-page input {
    max-width: fit-content;
    font-size: 2.5vh;
}

#contact-us-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.contact-form-elements {
    margin: 0.5rem;
}

.contact-form-required {
    color: red;
}

#contact-us-form-textbox {
    width: 60%;
    height: 30%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 2.5vh;
}

#submit-button {
    margin-left: auto;
    margin-right: auto;
    font-size: 0.75em;
}


/*..............................SECTION IMAGE PANE SECTION................................*/

.image-pane {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
}

.services-pic {
    max-height: 50%;
    max-width: 90%;
    margin: 0.25rem;
    border-radius: 8px;
    vertical-align: middle;
    object-fit: cover;
}

.image-pane-div {
    overflow-y: scroll;
    max-height: 30rem;
}


/*..............................GALLERY SECTION................................*/

.gallery-section {
    min-height: 78vh;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.gallery-pic {
    max-height: 50%;
    max-width: 90%;
    margin: 0.25rem;
    border-radius: 8px;
    vertical-align: middle;
    object-fit: cover;
}

/*..............................CONTACT SECTION................................*/

#contact-section {
    background-color: #1f1f1f;
    min-height: 45vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    text-align: center;
}

#item-contact img {
    height: 5vh;
    width: auto;
}

#item-contact h2 {
    font-size: 1.4em;
}

#item-contact p {
    font-size: 0.75em;
    padding-left: 2rem;
    padding-right: 2rem;
}

#item-contact a {
    font-size: 0.75em;
}

.contact-links {
    font-size: 0.75em;
}


/*..............................FOOTER................................*/

.footer {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #191919;
    color: white;
    font-size: 0.5em;
}

.footer a:hover {
    text-decoration: underline;
}


/*..............................RESPONSIVE CODE................................*/


/*Mobile screens*/

@media screen and (min-width: 320px) and (max-width: 425px) {
    .home-picture-content h1 {
        font-size: 2em;
    }
    .topnav a {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 0.5rem;
        font-size: 0.75em;
    }
    .dropdown .dropbtn {
        font-size: 0.75em;
        padding-top: 0.5rem;
    }
    #nav-bar img {
        width: auto;
        height: 5vh;
    }
}